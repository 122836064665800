import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./assets/styles/application.scss";
import Loading from "./components/loading";
import reportWebVitals from "./utilities/reportWebVitals";

const Driver = lazy(() => import("./pages/driver"));
const DriverForm = lazy(() => import("./pages/driver/form"));
const DriverDocs = lazy(() => import("./pages/driver/docs"));
const DriverJob = lazy(() => import("./pages/driver/jobposting"));
const DriverEnquiry = lazy(() => import("./pages/driver/enquiry"));
const DriverApplication = lazy(() => import("./pages/driver/application"));
const SurreyDriver = lazy(() => import("./pages/driver/surrey"));

const Assistant = lazy(() => import("./pages/assistant"));
const AssistantForm = lazy(() => import("./pages/assistant/form"));
const AssistantDocs = lazy(() => import("./pages/assistant/docs"));
const AssistantJob = lazy(() => import("./pages/assistant/jobposting"));
const AssistantEnquiry = lazy(() => import("./pages/assistant/enquiry"));
const AssistantApplication = lazy(() =>
  import("./pages/assistant/application")
);
const SurreyAssistant = lazy(() => import("./pages/assistant/surrey"));

const DBS = lazy(() => import("./pages/dbs"));
const EnhancedDBS = lazy(() => import("./pages/dbs/enhanced"));
const DBSPayment = lazy(() => import("./pages/dbs/payment"));
const DBSPaid = lazy(() => import("./pages/dbs/paid"));
const DBSPrivacy = lazy(() => import("./pages/dbs/privacy"));
const DBSTerms = lazy(() => import("./pages/dbs/terms"));

const DBSDriver = lazy(() => import("./pages/dbs/driver"));
const DBSAssistant = lazy(() => import("./pages/dbs/assistant"));

const Privacy = lazy(() => import("./pages/privacy"));
const Reference = lazy(() => import("./pages/reference"));
const Refer = lazy(() => import("./pages/reference/refer"));

const App = () => (
  <BrowserRouter>
    <Suspense fallback={Loading()}>
      <Switch>
        <Route exact path="/driver" component={Driver} />

        <Route exact path="/driverform" component={DriverForm} />

        <Route exact path="/docs/driver" component={DriverDocs} />

        <Route exact path="/driver2" component={Driver} />

        <Route exact path="/surreydriver" component={SurreyDriver} />

        <Route path="/jobposting/driver" component={DriverJob} />

        <Route path="/enquiry/driver" component={DriverEnquiry} />

        <Route path="/driver/:id" component={DriverApplication} />

        <Route path="/driver2/:id" component={DriverApplication} />

        <Route exact path="/pa" component={Assistant} />

        <Route exact path="/paform" component={AssistantForm} />

        <Route exact path="/pa2" component={Assistant} />

        <Route exact path="/surreypa" component={SurreyAssistant} />

        <Route path="/docs/pa" component={AssistantDocs} />

        <Route path="/jobposting/pa" component={AssistantJob} />

        <Route path="/enquiry/pa" component={AssistantEnquiry} />

        <Route path="/pa/:id" component={AssistantApplication} />

        <Route path="/pa2/:id" component={AssistantApplication} />

        <Route path="/privacy-policy" component={Privacy} />

        <Route path="/terms-of-service" component={Privacy} />

        <Route exact path="/dbs" component={DBS} />

        <Route exact path="/EnhancedDBS" component={EnhancedDBS} />

        <Route path="/dbs/payment" component={DBSPayment} />

        <Route path="/dbs/paid" component={DBSPaid} />

        <Route path="/dbs/privacy-policy" component={DBSPrivacy} />

        <Route path="/dbs/terms-of-service" component={DBSTerms} />

        <Route path="/dbsdriver" component={DBSDriver} />

        <Route path="/dbspa" component={DBSAssistant} />

        <Route path="/reference/:job/:id" component={Reference} />

        <Route path="/reference2" component={Refer} />

        <Redirect from="/" to="/driver" />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
