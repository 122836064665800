const Loading = () => (
  <div className="loading">
    <div className="item item-1">
      <span></span>
    </div>

    <div className="item item-2">
      <span></span>
    </div>

    <div className="item item-3">
      <span></span>
    </div>

    <div className="item item-4">
      <span></span>
    </div>
  </div>
);

export default Loading;
